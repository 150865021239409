import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ProtectedRoute from './Routes/Utilities/ProtectedRoute.js';

import OrderPage from "./Routes/OrderPage.js";
import ReportPage from "./Routes/ReportPage.js";
import SummaryPage from "./Routes/SummaryPage.js";
import UserPage from "./Routes/UserPage.js";
import RegisterPage from "./Routes/Unauthenticated/RegisterPage.js";
import LoginPage from "./Routes/Unauthenticated/LoginPage.js";
import VerifyOPT from "./Routes/Unauthenticated/VerifyOPT.js";
import ChangePassword from "./Routes/Unauthenticated/ChangePassword.js";
import ProductPage from "./Routes/ProductPage.js";
import NotFound from "./Routes/Error/NotFound.js";

import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  return (
    <BrowserRouter>
      <div className="bg-gray-200 min-h-screen">
        <Routes>
          <Route path='*' element={<NotFound />} />

          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/verify" element={<VerifyOPT />} />
          

          <Route element={<ProtectedRoute permissionLevels={[global.authorityLevel.first_manager, global.authorityLevel.second_manager, global.authorityLevel.employee]} />} >
            <Route path="/" element={<OrderPage />} />
            <Route path="/reset-password" element={<ChangePassword />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/report" element={<ReportPage />} />
            <Route path="/medicines" element={<ProductPage />} />
          </Route>

          <Route element={<ProtectedRoute permissionLevels={[global.authorityLevel.first_manager, global.authorityLevel.second_manager, global.authorityLevel.warehouse]} />} >
            <Route path="/users" element={<UserPage />} />
            <Route path="/summary" element={<SummaryPage />} />

          </Route>
        </Routes>
      </div>
      <SpeedInsights />
    </BrowserRouter>
  );
}

export default App;