import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-200">
            <div className="text-center">
                <h1 className="text-6xl font-bold text-red-600 mb-4">404</h1>
                <h2 className="text-2xl font-semibold mb-2">Sayfa Bulunamadı</h2>
                <p className="mb-8 text-gray-700">
                    Üzgünüz, aradığınız sayfa mevcut değil.
                </p>
                <Link 
                    to="/" 
                    className="px-6 py-3 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 transition duration-200"
                >
                    Ana Sayfaya Dön
                </Link>
            </div>
        </div>
    );
};

export default NotFound;