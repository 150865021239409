import { createClient } from "@supabase/supabase-js";

const url = process.env.REACT_APP_SUPABASE_URL
const key = process.env.REACT_APP_SUPABASE_KEY

let originSize = 0
let clonedSize = 0

const fetchWithAnalytics = async (url, options) => {
    const response = await fetch(url, options); // Normal fetch çağrısı

    // Response'u klonlayarak boyutunu ölçelim
    /*const clonedResponse = response.clone();
    const responseBody = await clonedResponse.text();
    const responseSize = new Blob([responseBody]).size; // Byte cinsinden boyut
    const originResponseSize = new Blob([response]).size; // Byte cinsinden boyut

    
    clonedSize += responseSize / (1024 * 1024)
    originSize += originResponseSize / (1024 * 1024)

    console.log(`Cloned Total Size: ${clonedSize} MB`);
    console.log(`Origin Total Size: ${originSize} MB`);*/

    return response; // Orijinal response'u döndür
};

const supabase = createClient(url, key, {
    global: {
        fetch: fetchWithAnalytics, // Fetch wrapper'ı buraya tanımlıyoruz
    },
});

export default supabase


