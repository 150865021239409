import React, { useState, useEffect } from "react";

export default function Dropdown({ selectedIndex, items, isActive=true, onSelected }) {

    const [isOpen, setIsOpen] = useState(false); // Dropdown açık/kapalı durumu
    const [selectedItem, setSelectedItem] = useState(null); // Seçilen öğe

    useEffect(() => {
        handleItemClick(items[selectedIndex])
    }, [selectedIndex])

    const toggleDropdown = () => {
        if (isActive) { // Only allow toggle if isActive is true
            setIsOpen(!isOpen); 
        }
    };

    const handleItemClick = (item) => {
        setSelectedItem(item); // Seçilen öğeyi güncelle
        setIsOpen(false); // Dropdown'u kapat
        onSelected(item)
    };

    return (
        <div className={`relative inline-block w-52`}>
            {/* Seçilen öğe veya varsayılan text */}
            <button
                onClick={toggleDropdown}
                disabled={!isActive}
                className={`bg-white-200 text-gray-700 font-semibold py-0.5 px-4 border border-gray-300 rounded-lg w-full text-left flex justify-between items-center ${
                    !isActive ? "cursor-not-allowed opacity-50" : ""
                }`}
            >
                {selectedItem ? selectedItem.text : "Bir seçenek seçin"}
                <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </button>

            {/* Dropdown menüsü */}
            {isOpen && (
                <ul className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-lg shadow-lg w-full">
                    {items.map((item) => (
                        <li
                            key={item.text}
                            onClick={() => handleItemClick(item)}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        >
                            {item.text}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}