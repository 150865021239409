export default function MailGenerator(orderObject) {
  if (!orderObject.selectedMedicines || orderObject.selectedMedicines.length == 0)
    return

  let message = `Merhaba,<br/><br/>`;

  if (orderObject.selectedPharmacy) {
    message += `${orderObject.selectedPharmacy.regions.name}, ${orderObject.selectedPharmacy.bricks.name}, ${orderObject.selectedPharmacy.name}<br/><br/>`;
  }

  orderObject.selectedMedicines.forEach(item => {
    if (item.currentQuantity > 0)
      message += `${item.name}: Adet: ${item.currentQuantity} + Mal Fazlası: ${item.currentFreeCount}<br/>`;
  });

  if (orderObject.selectedWarehouse) {
    message += `<br/>Alıcı: ${orderObject.selectedWarehouse.name}`;

    /*if (orderObject.selectedWarehouse.email)
      message += ` | ${orderObject.selectedWarehouse.email}`*/
  }

  return message;
}