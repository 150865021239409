import React from 'react';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';


const OrderItem = ({ order }) => {
    function getOrderStatusDetails(status) {
        switch (status) {
            case global.orderStatus.email_order:
                return {
                    text: 'Mail',
                    className: 'bg-gray-100 text-gray-600',
                };
            case global.orderStatus.active_order:
                return {
                    text: 'Aktif',
                    className: 'bg-orange-100 text-orange-600',
                };
            case global.orderStatus.completed_order:
                return {
                    text: 'Tamamlandı',
                    className: 'bg-green-100 text-green-600',
                };
            case global.orderStatus.deleted_order:
                return {
                    text: 'İptal Edildi',
                    className: 'bg-red-100 text-red-600',
                };
            default:
                return {
                    text: 'Bilinmeyen Durum',
                    className: 'bg-black-100 text-black-600'
                };
        }
    }

    const orderDate = new Date(order.created_at); 
    const gmtOffset = orderDate.getTimezoneOffset();
    const gmtDate = new Date(orderDate.getTime() - gmtOffset * 60000);
    const formattedDate = format(gmtDate, 'dd MMM yyyy, HH:mm', { locale: tr });

    return (
        <div className="border border-gray-400 pl-4 p-2 mb-2 rounded-lg shadow-sm bg-white hover:shadow-lg transition-shadow duration-300">
            {/* Order ID and Status */}
            <div className="flex justify-between items-center mb-6">
                {/* Sipariş Tarihi */}
                <span className="text-sm text-gray-500">
                    Sipariş Tarihi: <span className="font-medium text-gray-700">{formattedDate}</span>
                </span>
                {/* Sipariş Durumu */}
                <span className={`text-xs font-semibold px-3 py-1 rounded-full ${getOrderStatusDetails(order.status).className}`}>
                    {getOrderStatusDetails(order.status).text}
                </span>
            </div>

            {/* Created At (Tarih Bilgisi) */}


            {/* User, Pharmacy, and Warehouse Info */}
            <div className="flex flex-col sm:flex-row sm:justify-between mb-6 gap-4">
                {/* User Info */}
                <div className="w-full sm:w-1/3 bg-gray-50 p-2 rounded-lg">
                    <p className="text-gray-600">
                        <strong>{order.users.first_name} {order.users.last_name}</strong><br />
                        <span className="text-xs text-gray-500">{order.users.email}</span>
                    </p>
                </div>

                {/* Pharmacy Info */}
                <div className="w-full sm:w-1/3 bg-gray-50 p-2 rounded-lg">
                    <p className="text-gray-600">
                        <strong>{order.pharmacies.name}</strong><br />
                        <span className="text-sm text-gray-500">Brick: {order.pharmacies.bricks.name}, Bölge: {order.pharmacies.regions.name}</span>
                    </p>
                </div>

                {/* Warehouse Info */}
                <div className="w-full sm:w-1/3 bg-gray-50 p-2 rounded-lg place-content-center">
                    <p className="text-gray-600">
                        <strong>{order.warehouse.company_name}</strong>
                    </p>
                </div>
            </div>

            {/* Order Contents in Table */}
            <div className='overflow-x-scroll sm:overflow-x-auto'>
                <table className="w-full text-left border-collapse">
                    <thead>
                        <tr className="bg-gray-50">
                            <th className="border-b p-3 font-medium text-gray-700 text-sm">İlaç</th>
                            <th className="border-b p-3 font-medium text-gray-700 text-sm">Miktar</th>
                            <th className="border-b p-3 font-medium text-gray-700 text-sm">Mal Fazlası</th>
                            <th className="border-b p-3 font-medium text-gray-700 text-sm">Toplam Fiyat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.contents.map(content => (
                            <tr key={content.id} className="odd:bg-white even:bg-gray-50">
                                <td className="border-b p-3 text-gray-600">{content.medicines.name}</td>
                                <td className="border-b p-3 text-gray-600">{content.count}</td>
                                <td className="border-b p-3 text-gray-600">{content.free_count}</td>
                                <td className="border-b p-3 text-gray-600">{content.total_price.toFixed(2)} ₺</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrderItem;
