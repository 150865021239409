import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import supabase from '../../Database/DBConfig.js'

export default function RegisterPage() {
    const [showPassword, setShowPassword] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isPasswordEmpty, setPasswordEmpty] = useState(false)
    const [isFirstNameEmpty, setFirstNameEmpty] = useState(false)
    const [isLastNameEmpty, setLastNameEmpty] = useState(false)
    const [isPageReady, setPageReady] = useState(false)
    const [user, setUser] = useState({})
    const navigate = useNavigate();

    useEffect(() => {
        const checkSession = async () => {
            const { data } = await supabase.auth.getUser();
            if (!data.user) {
                navigate("/login")
                return
            }

            const { data: user } = await supabase.from('users').select().eq('uid', data.user.id).single();

            if (user && user.is_registered) {
                navigate("/")
                return
            }

            setPageReady(true)
        };

        checkSession();
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleRegister = async () => {
        let errorMessage = '';

        if (firstName.trim() == '')
            errorMessage = 'Ad boş olamaz!'
        else if (lastName.trim() == '')
            errorMessage = 'Soyad boş olamaz!'
        else if (password1.trim() === '')
            errorMessage = 'Şifre boş olamaz!';
        else if (password1 !== password2)
            errorMessage = 'Şifreler eşleşmiyor!';

        if (errorMessage) {
            setError(errorMessage);
            setPasswordEmpty(password1.trim() === '');
            setFirstNameEmpty(firstName.trim() === '');
            setLastNameEmpty(lastName.trim() === '');
            return;
        }

        setError('');
        setPasswordEmpty(false)
        setLoading(true);

        const { data, error } = await supabase.auth.updateUser({
            password: password1
        })

        console.log(error)

        if (error) {
            if (error.code == "weak_password")
                setError('Şifre en az 8 karakter, büyük/küçük harf ve özel karakter içermeli');
            else if (error.code == "same_password")
                setError('Şifre bir önceki ile aynı');
            else
                setError("Bilinmeyen hata")

            setLoading(false);
            return
        }

        const {error:userUpdateError} = await supabase
            .from('users')
            .update({ email: data.user.email, first_name: firstName, last_name: lastName, is_registered: true })
            .eq('uid', data.user.id)


        navigate("/")
    };

    if (!isPageReady) return null

    return (
        <>
            <div className='flex justify-center items-center w-screen h-screen'>
                <div className="flex justify-center items-center w-screen ">
                    <div className="shadow-md bg-white w-96 h-full p-4 rounded-[40px]">
                        <div className="flex flex-col gap-6 mt-10">
                            <div className="flex flex-col gap-5 font-bold">
                                <label>Kayıt Ol</label>
                                <hr />
                            </div>
                            <div>
                                <div className='flex flex-row gap-2 mb-2'>
                                    <div className='flex flex-col'>
                                        Ad
                                        <input
                                            type="text"
                                            value={firstName}
                                            maxLength={30}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            className={`${isFirstNameEmpty ? "focus:outline-2 focus:border-4 focus:outline-black border-2 border-rose-500" : "outline-none border-2 focus:border-black"} w-full p-1 rounded`}
                                        />
                                    </div>
                                    <div className='flex flex-col'>
                                        Soyad
                                        <input
                                            type="text"
                                            maxLength={30}
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            className={`${isLastNameEmpty ? "focus:outline-2 focus:border-4 focus:outline-black border-2 border-rose-500" : "outline-none border-2 focus:border-black"} w-full p-1 rounded`}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label>Şifre</label>
                                </div>
                                <div className="relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        value={password1}
                                        onChange={(e) => setPassword1(e.target.value)}
                                        className={`${isPasswordEmpty ? "focus:outline-2 focus:border-4 focus:outline-black border-2 border-rose-500" : "outline-none border-2 focus:border-black"} w-full p-1 rounded`}
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <svg className={showPassword ? "hidden" : "block"} viewBox="0 0 64 40" width="26" height="40">
                                            <ellipse cx="32" cy="20" rx="30" ry="18" stroke="#D9D9D9" strokeWidth="4" fill="none" />
                                            <circle cx="32" cy="20" r="10" stroke="#D9D9D9" strokeWidth="4" fill="none" />
                                            <line x1="10" y1="35" x2="54" y2="5" stroke="#D9D9D9" strokeWidth="4" />
                                        </svg>
                                        <svg className={!showPassword ? "hidden" : "block"} viewBox="0 0 64 40" width="26" height="40">
                                            <ellipse cx="32" cy="20" rx="30" ry="18" stroke="#D9D9D9" strokeWidth="4" fill="none" />
                                            <circle cx="32" cy="20" r="10" stroke="#D9D9D9" strokeWidth="4" fill="none" />
                                            <circle cx="32" cy="20" r="5" fill="#D9D9D9" />
                                        </svg>
                                    </button>
                                </div>
                                <div className='mt-4'>
                                    <label>Şifre tekrar</label>
                                </div>
                                <div className="relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        value={password2}
                                        onChange={(e) => setPassword2(e.target.value)}
                                        className={`${isPasswordEmpty ? "focus:outline-2 focus:border-4 focus:outline-black border-2 border-rose-500" : "outline-none border-2 focus:border-black"} w-full p-1 rounded`}
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <svg className={showPassword ? "hidden" : "block"} viewBox="0 0 64 40" width="26" height="40">
                                            <ellipse cx="32" cy="20" rx="30" ry="18" stroke="#D9D9D9" strokeWidth="4" fill="none" />
                                            <circle cx="32" cy="20" r="10" stroke="#D9D9D9" strokeWidth="4" fill="none" />
                                            <line x1="10" y1="35" x2="54" y2="5" stroke="#D9D9D9" strokeWidth="4" />
                                        </svg>
                                        <svg className={!showPassword ? "hidden" : "block"} viewBox="0 0 64 40" width="26" height="40">
                                            <ellipse cx="32" cy="20" rx="30" ry="18" stroke="#D9D9D9" strokeWidth="4" fill="none" />
                                            <circle cx="32" cy="20" r="10" stroke="#D9D9D9" strokeWidth="4" fill="none" />
                                            <circle cx="32" cy="20" r="5" fill="#D9D9D9" />
                                        </svg>
                                    </button>
                                </div>
                                <div className='h-6'>
                                    {error && <div className="text-red-500">{error}</div>}
                                </div>
                            </div>
                            <div className="w-full flex justify-center">
                                <button
                                    onClick={handleRegister}
                                    disabled={loading}
                                    className={`bg-blue-500 ${loading ? 'bg-gray-500' : 'hover:bg-blue-700'} text-white font-bold py-2 px-10 rounded-full`}>
                                    {loading ? (
                                        <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="white" strokeWidth="4" fill="none" />
                                            <path className="opacity-75" fill="white" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z" />
                                        </svg>
                                    ) : (
                                        'Kayıt Ol'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}