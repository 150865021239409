import { useState } from "react";

export default function SelectedProduct({ product, onSelected, onQuantityChanged }) {
    const [isMouseDown, setIsMouseDown] = useState(false);

    const onFocusInput = () => {
        setIsMouseDown(true);
    };

    const onBlurInput = (e) => {
        setIsMouseDown(false);
        e.stopPropagation();
    };

    const handleClick = (e) => {
        if (!isMouseDown) {
            onSelected(product.id);
        }
    };

    const onQuantityChangedByInput = (e) => {
        let quantity = Math.round(e.target.value)

        if (e.target.value.length > 1 && e.target.value.startsWith('0')) {
            e.target.value = e.target.value.replace(/^0+/, '');
        }

        if (quantity < 0)
            quantity = 0
        if(quantity > 9999)
            quantity = 9999
        
        let selectedFreePair = null
        const freePairs = product.medicine_free_pairs.sort((a,b) => a.count - b.count)
        freePairs.map(pair => {
            if (pair.count <= quantity) {
                selectedFreePair = pair
            }
            else
                return
        })

        if (!selectedFreePair) {
            onQuantityChanged(product.id, Number(quantity), Number(0));
            return
        }

        const freeRatio = selectedFreePair.free_count / selectedFreePair.count;
        let freeCount = Math.round(quantity * freeRatio)

        onQuantityChanged(product.id, Number(quantity), Number(freeCount));
    };

    return (
        <tr className="border-b pl-2 mb-2 cursor-pointer overflow-hidden text-center" onClick={handleClick} key={product.id}>
            <td className="p-2">{product.name}</td>
            <td className="p-2">
                <input
                    type="number"
                    className="w-20 no-spinners text-center border rounded px-2"
                    value={product.currentQuantity}
                    onChange={onQuantityChangedByInput}
                    onClick={e => e.stopPropagation()}
                    onFocus={onFocusInput}
                    onBlur={onBlurInput}
                />
            </td>
            <td className="p-2">{(product.currentFreeCount).toFixed(0)}</td>
            <td className="p-2">{(product.currentFreeCount + product.currentQuantity).toFixed(0)}</td>
            <td className="p-2">{isNaN((product.currentQuantity * product.price) / (product.currentQuantity + product.currentFreeCount)) 
        ? '0.00TL' 
        : ((product.currentQuantity * product.price) / (product.currentQuantity + product.currentFreeCount)).toFixed(2) + 'TL'}</td>
            <td className="p-2">{((product.currentQuantity * product.price)).toFixed(2)}TL</td>
        </tr>
    );
}