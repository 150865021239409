import supabase from "../DBConfig";

const CACHE_DURATION = 24 * 60 * 60 * 1000;

const TABLE_NAME = "bricks"
const TIME_STORAGE_KEY = "lastFetchTimeKey" + TABLE_NAME
const CACHE_STORAGE_KEY = "cachedKey" + TABLE_NAME

export const getBrickList = async () => {

    const storedFetchTime = sessionStorage.getItem(TIME_STORAGE_KEY);
    const lastFetchTime = storedFetchTime ? parseInt(storedFetchTime, 10) : null;

    if (false && lastFetchTime && (Date.now() - lastFetchTime < CACHE_DURATION)) {

        const cachedData = sessionStorage.getItem(CACHE_STORAGE_KEY)

        if (cachedData) {
            const data = JSON.parse(cachedData)
            return { data };
        }
    }

    let query = supabase.from(TABLE_NAME).select()

    if (global.userData.authority_level === global.authorityLevel.employee) {
        const brickIds = global.userData.user_brick_pairs.map(pair => pair.brick_id);
        query = query.in('id', brickIds);
    }
    
    if (global.userData.authority_level === global.authorityLevel.second_manager) {
        const regionIds = global.userData.user_region_pairs.map(pair => pair.region_id);
        query = query.in('region_id', regionIds);
    }

    const { data, error } = await query;

    if (error) throw new Error(error.message);

    sessionStorage.setItem(CACHE_STORAGE_KEY, JSON.stringify(data))
    sessionStorage.setItem(TIME_STORAGE_KEY, Date.now());

    return { data, error };
};