import React, { useState, useEffect } from "react";
import supabase from "../../Database/DBConfig.js";
import Dropdown from "./Dropdown.js";
import ItemSelector from "./ItemSelector.js";

import { getMedicineList } from "../../Database/Tables/MedicineTable.js"
import { getBrickList } from "../../Database/Tables/BricksTable.js"
import { getRegionList } from "../../Database/Tables/RegionsTable.js"

import { FunctionsHttpError } from '@supabase/supabase-js'

const userTypes = () => {
    const types = [{
        level: global.authorityLevel.employee,
        text: "Mümessil"
    }];
    if (global.userData.authority_level === global.authorityLevel.first_manager) {
        types.push({
            level: global.authorityLevel.second_manager,
            text: "Bölge Müdürü"
        });
    }
    return types;
};

export default function AddUser({ editingUser, isEditing, onUserAddedEvent, onUserUpdatedEvent, onCanceledEvent }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [selectedType, setSelectedType] = useState(userTypes()[0]);
    const [regions, setRegions] = useState([]);
    const [bricks, setBricks] = useState([]);
    const [medicines, setMedicines] = useState([]);
    const [email, setEmail] = useState("")
    const [isEmailError, setEmailError] = useState(false)
    const [result, setResult] = useState("")

    const fetchData = async () => {
        setIsFetching(true)

        const { data: brickData } = await getBrickList()
        const { data: regionData } = await getRegionList()
        const { data: medicinesData } = await getMedicineList(global.userData.company_id)

        let brickPair = [];
        let regionPair = [];
        let medicinePair = [];

        if (isEditing && editingUser) {
            brickPair = editingUser.user_brick_pairs;
            regionPair = editingUser.user_region_pairs;
            medicinePair = editingUser.user_medicine_pairs;
        }

        const formatedRegionData = regionData.map(region => ({
            id: region.id,
            name: region.name,
            isSelected: regionPair ? regionPair.some(pair => pair.region_id === region.id) : false
        }))

        const formatedBrickData = brickData.map(brick => ({
            id: brick.id,
            name: brick.name,
            isSelected: brickPair ? brickPair.some(pair => pair.brick_id === brick.id) : false
        }))

        const formatedMedicines = medicinesData.map(medicine => ({
            id: medicine.id,
            name: medicine.name,
            isSelected: medicinePair ? medicinePair.some(pair => pair.medicine_id === medicine.id) : false
        }));

        setMedicines(formatedMedicines)
        setBricks(formatedBrickData)
        setRegions(formatedRegionData)

        setIsFetching(false)
    }

    useEffect(() => {
        if (isEditing && editingUser) {
            setEmail(editingUser.email)
            setSelectedType({ level: editingUser.authority_level })
        }

        fetchData()

    }, [isEditing, editingUser])

    const onTypeSelected = (type) => {
        setSelectedType(type);
    };

    const handleRegionSelect = (selectedRegion, isSelected) => {
        setRegions((prevRegions) =>
            prevRegions.map((region) =>
                region.id === selectedRegion.id
                    ? { ...region, isSelected: !region.isSelected }
                    : region
            )
        );
    };

    const handleBrickSelect = (selectedBrick, isSelected) => {
        setBricks((prevBricks) =>
            prevBricks.map((brick) =>
                brick.id === selectedBrick.id
                    ? { ...brick, isSelected: !brick.isSelected }
                    : brick
            )
        );
    };

    const handleMedicineSelect = (selectedMedicine, isSelected) => {
        setMedicines((prevMedicines) =>
            prevMedicines.map((medicine) =>
                medicine.id === selectedMedicine.id
                    ? { ...medicine, isSelected: !medicine.isSelected }
                    : medicine
            )
        );
    };

    const handleAddUser = () => {
        const sendInvite = async () => {
            setIsLoading(true)
            const { data, error: inviteError } = await supabase.functions.invoke("add-user", {
                body: { email, authorityLevel: selectedType.level },
            })

            if (inviteError) {
                let errorMessage = inviteError

                if (inviteError instanceof FunctionsHttpError) {
                    errorMessage = await inviteError.context.json()
                }

                if (errorMessage.error.code === "23505") {
                    setEmailError(true)
                    setResult("Davetiye tekrar gönderildi!")
                    setIsLoading(false)
                    return
                }

                if (errorMessage.error.code === "email_exists") {
                    setEmailError(true)
                    setResult("Email daha önce kullanılmış!")
                    setIsLoading(false)
                    return
                }

                setEmailError(true)
                setResult("Bir hata meydana geldi!")
                setIsLoading(false)
                return
            }

            if (selectedType.level === global.authorityLevel.second_manager) {
                await Promise.all(regions.map(async (region) => {
                    if (region.isSelected) {
                        const { error } = await supabase
                            .from('user_region_pairs')
                            .insert([{ region_id: region.id, user_id: data.uid }]);
                        if (error) {
                            console.error("Error adding pair:", error);
                        }
                    }
                }));
            }
            else if (selectedType.level === global.authorityLevel.employee) {
                await Promise.all(medicines.map(async (medicine) => {
                    if (medicine.isSelected) {
                        const { error } = await supabase
                            .from('user_medicine_pairs')
                            .insert([{ medicine_id: medicine.id, user_id: data.uid }]);
                        if (error) {
                            console.error("Error adding pair:", error);
                        }
                    }
                }));

                await Promise.all(bricks.map(async (brick) => {
                    if (brick.isSelected) {
                        const { error } = await supabase
                            .from('user_brick_pairs')
                            .insert([{ brick_id: brick.id, user_id: data.uid }]);
                        if (error) {
                            console.error("Error adding pair:", error);
                        }
                    }
                }));
            }

            setEmailError(false)
            setIsLoading(false)
            setResult("Davetiye Gönderildi!")
            onUserAddedEvent()
        }

        sendInvite()
    }

    const handleEditUser = () => {
        const editUser = async () => {
            setIsLoading(true)
            if (selectedType.level === global.authorityLevel.second_manager) {
                await supabase
                    .from('user_region_pairs')
                    .delete()
                    .eq('user_id', editingUser.uid);

                await Promise.all(regions.map(async (region) => {
                    if (region.isSelected) {
                        const { error } = await supabase
                            .from('user_region_pairs')
                            .insert([{ region_id: region.id, user_id: editingUser.uid }]);
                        if (error) {
                            console.error("Error adding pair:", error);
                        }
                    }
                }));
            }
            else if (selectedType.level === global.authorityLevel.employee) {
                await supabase
                    .from('user_brick_pairs')
                    .delete()
                    .eq('user_id', editingUser.uid);

                await supabase
                    .from('user_medicine_pairs')
                    .delete()
                    .eq('user_id', editingUser.uid);

                await Promise.all(medicines.map(async (medicine) => {
                    if (medicine.isSelected) {
                        const { error } = await supabase
                            .from('user_medicine_pairs')
                            .insert([{ medicine_id: medicine.id, user_id: editingUser.uid }]);
                        if (error) {
                            console.error("Error adding pair:", error);
                        }
                    }
                }));

                await Promise.all(bricks.map(async (brick) => {
                    if (brick.isSelected) {
                        const { error } = await supabase
                            .from('user_brick_pairs')
                            .insert([{ brick_id: brick.id, user_id: editingUser.uid }]);
                        if (error) {
                            console.error("Error adding pair:", error);
                        }
                    }
                }));
            }

            setEmailError(false)
            setIsLoading(false)
            onUserUpdatedEvent()
            setResult("Kullanıcı güncellendi!")
        }

        editUser()
    }

    const clearForm = () => {
        setMedicines(medicines.map(item => ({
            ...item,
            isSelected: false
        })))

        setBricks(bricks.map(item => ({
            ...item,
            isSelected: false
        })))

        setRegions(regions.map(item => ({
            ...item,
            isSelected: false
        })))

        setEmail("")
        setEmailError(false)
        setResult("")
        onCanceledEvent()
    }

    return (
        <div className="flex flex-col gap-4 md:flex-row mr-1">
            <div className="w-full h-full bg-white p-4 rounded-lg shadow-md">
                {/* Başlık */}
                <div className="m-2 flex justify-center items-center">
                    <label className="font-bold text-lg">
                        {isEditing ? `Düzenlenen Kişi: ${editingUser.first_name} ${editingUser.last_name}` : "Kişi Ekle"}
                    </label>
                </div>

                {/* Yatay çizgi */}
                <div className="flex justify-center items-center">
                    <hr className="w-5/6" />
                </div>

                {/* Form Alanları (Yan Yana İsim ve Fiyat) */}
                <div className="flex flex-col justify-center items-center gap-4">
                    <div className="flex justify-center items-center">
                        <div className="mt-4 flex items-center flex-col sm:flex-row gap-4">
                            {/* İsim Alanı */}
                            <div className="flex flex-col">
                                <label className="font-semibold">Mail Adresi</label>
                                <input
                                    type="text"
                                    maxLength="60"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    placeholder="Mail Adresi"
                                    disabled={isEditing}
                                    className={`${isEditing ? "opacity-50 cursor-not-allowed" : ""} p-1 w-60 rounded-md text-sm ${isEmailError ?
                                        "focus:outline-2 focus:border-4 focus:outline-black border-2 border-rose-500" :
                                        "outline-none border border-gray-400 focus:border-black"}`}
                                />
                            </div>

                            <div className="flex flex-col">
                                <label className="font-semibold mt-1 sm:mt-6"></label>
                                <Dropdown selectedIndex={userTypes().findIndex(type => selectedType.level === type.level)} isActive={!isEditing} items={userTypes()} onSelected={onTypeSelected} />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col xl:flex-row gap-10">
                        {selectedType.level === global.authorityLevel.second_manager && (
                            <ItemSelector isLoading={isFetching} header1={"Tüm Bölgeler"} header2={"Seçili Bölgeler"} searchText={"Bölge Ara"} onItemSelected={handleRegionSelect} items={regions} />
                        )
                        }
                        {
                            selectedType.level === global.authorityLevel.employee && (
                                <><ItemSelector isLoading={isFetching} header1={"Tüm Brikler"} header2={"Seçili Brickler"} searchText={"Brick Ara"} onItemSelected={handleBrickSelect} items={bricks} />
                                    <ItemSelector isLoading={isFetching} header1={"Tüm İlaçlar"} header2={"Seçili İlaçlar"} searchText={"İlaç Ara"} onItemSelected={handleMedicineSelect} items={medicines} /></>
                            )
                        }
                    </div>
                </div>

                <div className="flex flex-col justify-center items-center">
                    <label className={`${isEmailError ? "text-red-600" : "text-green-500"} mt-4 font-bold h-4`}>{result}</label>
                    {/* Ekle ve Temizle Butonları */}
                    <div className="mt-6 flex justify-center">
                        <button onClick={isEditing ? handleEditUser : handleAddUser}
                            className="bg-blue-500 text-white px-6 py-2 rounded-md text-lg"
                        >
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="white" strokeWidth="4" fill="none" />
                                    <path className="opacity-75" fill="white" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z" />
                                </svg>
                            ) : (
                                isEditing ? "Güncelle" : "Ekle"
                            )}
                        </button>
                        <button onClick={clearForm}
                            className="bg-gray-300 text-black px-6 py-2 rounded-md text-lg ml-4"
                        // Temizle butonuna fonksiyon bağlandı
                        >
                            {isEditing ? "Vazgeç" : "Temizle"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
