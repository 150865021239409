import Sidebar from "../../Components/Sidebar/Sidebar.js";
import React, { useEffect, useState } from "react";
import supabase from "../../Database/DBConfig.js";
import AddProduct from "../../Components/AddProduct.js";
import Dropdown from "./Dropdown.js";

const authorityLevelFilter = [{ text: "Hepsi", level: 0 }, { text: "Satış Müdürü", level: global.authorityLevel.first_manager }, { text: "Bölge Müdürü", level: global.authorityLevel.second_manager }, { text: "Mümessil", level: global.authorityLevel.employee }]

export default function ProductPage({key, editingUser, onEditEvent, onDeleteEvent }) {
    // State to store product informationü
    const [userList, setUserList] = useState([]); // Liste için yeni state
    const [isFetching, setIsFetching] = useState(false)
    const [searchQuery, setSearchQuery] = useState("");
    const [searchAuthorityLevel, setSearchAuthorityLevel] = useState(0)

    const fetchData = async () => {
        setIsFetching(true)
        let query = supabase.from('users')
            .select('*, user_region_pairs (id, region_id), user_medicine_pairs (id, medicine_id), user_brick_pairs (id, brick_id, bricks (region_id))')
            .match({ 'company_id': global.userData.company_id, 'is_deleted': false })
            .order('authority_level')
            .order('first_name')
            .order('last_name')

        let { data, error } = await query
   
        if (error) {
            console.error("Error fetching medicines:", error);
            return;
        }

        if (global.userData.authority_level === global.authorityLevel.second_manager) {
            const regionIds = global.userData.user_region_pairs.map(pair => pair.region_id);
        
            data = data.filter(user => 
                user.user_brick_pairs.some(pair => regionIds.includes(pair.bricks?.region_id)) || 
                user.authority_level < global.userData.authority_level || 
                user.uid === global.userData.uid
            );
        }
        
        setUserList(data);
        setIsFetching(false)
    };

    useEffect(() => {
        fetchData(); // Fetch data on component mount
    }, []);

    const handleEditProduct = (user) => {
        onEditEvent(user);
    };

    const confirmDelete = async (uid) => {
        if (window.confirm("Emin misiniz? Bu işlem geri alınamaz.")) {

            const { error } = await supabase
                .from('users')
                .update({ is_deleted: true })
                .eq('uid', uid);

            if (error) {
                console.error("Error deleting product:", error);
                return;
            }

            const { data, error: deleteError } = await supabase.auth.admin.deleteUser(uid, true)
            console.log(deleteError)
            fetchData();

            onDeleteEvent(uid)
        }
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
    };

    const handleLevelFilter = (item) => {
        setSearchAuthorityLevel(item.level)
    }

    const filteredUsers = userList.filter((user) => {
        const isMatch =
            user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase());

        const isAuthorityLevelMatch =
            user.authority_level === searchAuthorityLevel ||
            searchAuthorityLevel === 0; // If selectedAuthorityLevel is 0, include all

        return isMatch && isAuthorityLevelMatch;
    });


    return (
        <>
            {/* İlaç ve Mal Fazlası Listesi */}
            <div className="mt-8 w-full h-auto bg-white p-4 rounded-lg shadow-md">
                <div className="m-2 flex flex-col justify-center items-center">
                    <label className="font-bold text-lg">Kullanıcı Listesi</label>
                    {/* Arama Kutusu */}
                    <div className="relative flex items-center">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearch}
                            placeholder="Kullanıcı ara..."
                            className="border border-gray-300 rounded-md px-3 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 absolute left-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M15.5 10.5a5 5 0 11-10 0 5 5 0 0110 0z" />
                        </svg>
                    </div>
                    <div className="mt-2">
                        <Dropdown selectedIndex={authorityLevelFilter.findIndex(item => item.level === searchAuthorityLevel)} items={authorityLevelFilter} onSelected={handleLevelFilter} />
                    </div>
                </div>

                <div className="overflow-y-auto max-h-96 border border-gray-200 rounded-md p-2">
                    {isFetching ? (
                        <div className="flex justify-center">
                            <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="black" strokeWidth="4" fill="none" />
                                <path className="opacity-75" fill="black" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z" />
                            </svg>
                        </div>
                    ) : (userList.length === 0 ? (
                        <div className="text-center text-gray-500">Kullanıcı bulunamadı</div> // 'b' senaryosu
                    ) : (
                        <ul>
                            {filteredUsers.map((user) => (
                                <li key={user.id} className={` ${user.uid === editingUser.uid ? 'bg-gray-200' : 'bg-gray-50'} p-4 mb-2 rounded-md shadow-sm flex flex-col`}>
                                    <div className="flex sm:flex-row flex-col justify-between items-center mb-2">
                                        <div className="sm:flex-1 flex sm:items-start items-center flex-col overflow-hidden">
                                            <span className="font-semibold text-lg truncate">{user.first_name} {user.last_name}</span>
                                            <span className="text-gray-600 block">{user.email}</span>
                                            <span className="text-gray-600 block">{user.authority_level === global.authorityLevel.first_manager && "Satış Müdürü"}
                                                {user.authority_level === global.authorityLevel.second_manager && "Bölge Müdürü"}
                                                {user.authority_level === global.authorityLevel.employee && "Mümessil"}
                                            </span>
                                        </div>
                                        <div className="flex sm:flex-row flex-col items-center space-x-2"> {/* Use items-center to align horizontally */}
                                            <div className="h-6">
                                                {!user.is_registered && <span>Hesap Oluşturulmamış</span>}
                                            </div>
                                            {user.authority_level > global.userData.authority_level && <>
                                                <button
                                                    className="text-blue-500 font-bold hover:underline"
                                                    onClick={() => handleEditProduct(user)}
                                                >
                                                    Düzenle
                                                </button>
                                                <button
                                                    className="text-red-500 font-bold hover:underline"
                                                    onClick={() => confirmDelete(user.uid)}
                                                >
                                                    Sil
                                                </button></>}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ))}
                </div>
            </div >
        </>
    );
}
