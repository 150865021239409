import supabase from "../DBConfig";

const CACHE_DURATION = 24 * 60 * 60 * 1000;

const TABLE_NAME = "companies"
const TIME_STORAGE_KEY = "lastFetchTimeKey" + TABLE_NAME
const CACHE_STORAGE_KEY = "cachedKey" + TABLE_NAME

export const getWarehouseList = async () => {
    const storedFetchTime = sessionStorage.getItem(TIME_STORAGE_KEY);
    const lastFetchTime = storedFetchTime ? parseInt(storedFetchTime, 10) : null;

    if (false && lastFetchTime && (Date.now() - lastFetchTime < CACHE_DURATION)) {

        const cachedData = sessionStorage.getItem(CACHE_STORAGE_KEY)

        if (cachedData) {
            const data = JSON.parse(cachedData)
            return { data };
        }
    }

    const { data, error } = await supabase.from(TABLE_NAME).select().eq('company_type', 1)

    if (error) throw new Error(error.message);

    sessionStorage.setItem(CACHE_STORAGE_KEY, JSON.stringify(data))
    sessionStorage.setItem(TIME_STORAGE_KEY, Date.now());

    return { data, error };
};