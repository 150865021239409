import { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import userModule from '../../Database/UserConfig.js';
import supabase from '../../Database/DBConfig.js'

const ProtectedRoute = ({ permissionLevels }) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const checkSession = async () => {
            const { data, error } = await supabase.auth.getUser();

            if (error) {
                console.error("Error fetching session:", error);
                navigate('/login');
            } else {
                setUser(data.user);

                if (data.user) {
                    // Kullanıcı oturum açtıysa, isPasswordGenerated kontrol et
                    const { data: userData, error: userError } = await supabase
                        .from('users')
                        .select("*, user_region_pairs (id,region_id), user_medicine_pairs (id, medicine_id), user_brick_pairs (id, brick_id)")
                        .eq('uid', data.user.id)
                        .single();
                        
                    if (userData.is_deleted) {
                        await supabase.auth.signOut()
                        navigate('/login');
                    }
                    else if (!userData.is_registered) {
                        navigate('/register');
                    }
                    else if (!permissionLevels.includes(userData.authority_level)) {
                        navigate('/unauthorized');
                    }

                    userModule.setUser(data.user)
                    userModule.setUserData(userData)
                }
            }
            setLoading(false);
        };

        checkSession();
    }, [navigate]);

    if (loading) return;

    if (!user) {
        return <Navigate to="/login" />;
    }

    return <Outlet />;
};

export default ProtectedRoute;