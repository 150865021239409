import Sidebar from "../Components/Sidebar/Sidebar"
import AddUser from "../Components/AddUser/AddUser"
import UserList from "../Components/AddUser/UserList"

import React, { useState } from "react";

export default function ProductPage() {
    const [isEditing, setEditing] = useState(false)
    const [editingUser, setEditingUser] = useState({})
    const [key, setKey] = useState(0); 

    const update = () => {
      setKey((prevKey) => prevKey + 1); 
    };

    const handleEdit = user => {
        setEditing(true)
        setEditingUser(user)
    }

    const handleDelete = uid => {
        if (isEditing && editingUser.uid === uid) {
            setEditing(false)
            setEditingUser({})
        }
    }

    const handleCancelEdit = () => {
        setEditing(false)
        setEditingUser({})
    }

    return (<>
        <Sidebar />
        <div className="ml-12 md:ml-40 p-2">
            <AddUser isEditing={isEditing} editingUser={editingUser} onCanceledEvent={handleCancelEdit} onUserAddedEvent={update} onUserUpdatedEvent={update} />
            <UserList key={key} editingUser={editingUser} onEditEvent={handleEdit} onDeleteEvent={handleDelete} />
        </div>
    </>)
}