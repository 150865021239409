import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import supabase from '../../Database/DBConfig.js'

export default function VerifyPage() {
    const navigate = useNavigate();

    useEffect(() => {
        const checkSession = async () => {
            const { token_hash, type, redirect_url } = Object.fromEntries(new URLSearchParams(window.location.search))
            const {
                data,
                error,
            } = await supabase.auth.verifyOtp({ token_hash, type })

            console.log("redirect_url")
            console.log(redirect_url)

            if (redirect_url)
                window.location.href = redirect_url
            else
                navigate("/")
        };

        checkSession();
    }, []);

    return null
}