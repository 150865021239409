import React, { useState } from "react";
import PropTypes from 'prop-types';

const SearchInput = ({ value, onChange, placeholder }) => (
    <div className="relative flex items-center">
        <input
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className="border w-full h-10 border-gray-300 rounded-md m-1 px-3 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 absolute left-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M15.5 10.5a5 5 0 11-10 0 5 5 0 0110 0z" />
        </svg>
    </div>
);

const ItemButton = ({ item, isSelected, onClick }) => (
    <button onClick={onClick} className="flex border-2 items-center bg-white w-full h-10">
        <div className={`ml-2 text-xs ${isSelected ? '' : ''}`}>{item.name}</div>
    </button>
);

const ItemSelector = ({ isLoading, items, header1, header2, searchText, onItemSelected }) => {
    const [allItemFilter, setAllItemFilter] = useState("");
    const [selectedItemFilter, setSelectedItemFilter] = useState("");

    const handleAllItemFilter = e => setAllItemFilter(e.target.value.toLowerCase());
    const handleSelectedItemFilter = e => setSelectedItemFilter(e.target.value.toLowerCase());

    return (
        <div className="flex flex-col sm:flex-row justify-center items-center gap-2">
            <div className="flex flex-col justify-center items-center">
                <label className="font-semibold">{header1}</label>
                <div className="w-60 h-60 bg-gray-100">
                    <SearchInput
                        value={allItemFilter}
                        onChange={handleAllItemFilter}
                        placeholder={searchText + "..."}
                    />
                    <div className="gap-0.5 w-full h-48 overflow-y-auto">
                        {isLoading ? (
                            <svg className="relative left-1/2 top-1/2 animate-spin h-5 w-5" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="black" strokeWidth="4" fill="none" />
                                <path className="opacity-75" fill="blue" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z" />
                            </svg>
                        ) : (
                            items
                                .filter(item => !item.isSelected && item.name.toLowerCase().includes(allItemFilter))
                                .map(item => (
                                    <ItemButton
                                        key={item.name}
                                        item={item}
                                        isSelected={false}
                                        onClick={() => onItemSelected(item, true)}
                                    />
                                )))}
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center">
                <label className="font-semibold">{header2}</label>
                <div className="w-60 h-60 bg-gray-100">
                    <SearchInput
                        value={selectedItemFilter}
                        onChange={handleSelectedItemFilter}
                        placeholder={searchText + "..."}
                    />
                    <div className="gap-0.5 w-full h-48 overflow-y-auto">
                        {isLoading ? (
                            <svg className="relative left-1/2 top-1/2 animate-spin h-5 w-5" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="black" strokeWidth="4" fill="none" />
                                <path className="opacity-75" fill="blue" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z" />
                            </svg>
                        ) : (

                            items
                                .filter(item => item.isSelected && item.name.toLowerCase().includes(selectedItemFilter))
                                .map(item => (
                                    <ItemButton
                                        key={item.name}
                                        item={item}
                                        isSelected={true}
                                        onClick={() => onItemSelected(item, false)}
                                    />
                                ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemSelector;