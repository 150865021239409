import React, { useEffect, useState } from "react";
import supabase from "../Database/DBConfig.js";

export default function AddProduct({ isEditing, editingProduct, onProductAdded, onProductUpdated, onCanceled }) {
    const [productName, setProductName] = useState("");
    const [price, setPrice] = useState("");
    const [pairs, setPairs] = useState([]);
    const [count, setCount] = useState("");
    const [freeCount, setFreeCount] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isEditing && editingProduct) {
            setProductName(editingProduct.name);
            setPrice(editingProduct.price);
            setPairs(editingProduct?.medicine_free_pairs ?? []);
        }
    }, [editingProduct, isEditing]);

    // Handle adding a count-free count pair
    const handleAddPair = () => {
        if (count && freeCount) {
            setCount(""); // Clear the input fields after adding
            setFreeCount("");

            if (!pairs.find((pair) => pair.count == count)) {
                const newPair = { id: Date.now(), count: Number(count), free_count: Number(freeCount) };
                setPairs((prevPairs) => [...prevPairs, newPair]);
            }
        }
    };

    // Handle deleting a count-free count pair
    const handleDeletePair = (id) => {
        setPairs((prevPairs) => prevPairs.filter((pair) => pair.id !== id));
    };

    // Handle adding or updating a product
    const handleSaveProduct = async () => {
        setIsLoading(true);
        if (productName && price) {
            if (isEditing) {
                // Update existing product
                const { error } = await supabase
                    .from('medicines')
                    .update({ name: productName, price })
                    .eq('id', editingProduct.id);

                if (error) {
                    console.error("Error updating product:", error);
                    return;
                }

                // First, delete existing pairs for the product
                await supabase
                    .from('medicine_free_pairs')
                    .delete()
                    .eq('medicine_id', editingProduct.id);

                // Then, insert the updated pairs for the product
                await Promise.all(pairs.map(async (pair) => {
                    const { error } = await supabase
                        .from('medicine_free_pairs')
                        .insert([{ medicine_id: editingProduct.id, count: pair.count, free_count: pair.free_count }]);
                    if (error) {
                        console.error("Error adding pair:", error);
                    }
                }));

                onProductUpdated()
            } else {
                // Add new product
                const { data, error } = await supabase
                    .from('medicines')
                    .insert([{ name: productName, price, created_by: global.user.id, company_id: global.userData.company_id }])
                    .select();

                if (error) {
                    console.error("Error adding product:", error);
                    return;
                }

                // Add pairs for the new product
                await Promise.all(pairs.map(async (pair) => {
                    const { error } = await supabase
                        .from('medicine_free_pairs')
                        .insert([{ medicine_id: data[0].id, count: pair.count, free_count: pair.free_count }]);
                    if (error) {
                        console.error("Error adding pair:", error);
                    }
                }));

                clearForm();
                onProductAdded()
            }
        }

        setIsLoading(false);
    };

    // Clear input fields
    const clearForm = () => {
        setProductName("");
        setPrice("");
        setPairs([]);
        setCount("");
        setFreeCount("");

        onCanceled()
    };

    // Handle price input with validation
    const handlePriceChange = (e) => {
        const value = e.target.value;

        // Limit price to 1000 and ensure 2 decimal places
        if (value === "" || (parseFloat(value) <= 1000 && /^\d*\.?\d{0,2}$/.test(value))) {
            setPrice(value);
        }
    };

    return (<>
        <div className="flex flex-col gap-4 md:flex-row mr-1">
            <div className="w-full h-full bg-white p-4 rounded-lg shadow-md">
                {/* Başlık */}
                <div className="m-2 flex justify-center items-center">
                    <label className="font-bold text-lg">
                        {isEditing ? "Düzenlenen İlaç " + editingProduct.name : "Ürün Ekle"}
                    </label>
                </div>

                {/* Yatay çizgi */}
                <div className="flex justify-center items-center">
                    <hr className="w-5/6" />
                </div>

                {/* Form Alanları (Yan Yana İsim ve Fiyat) */}
                <div className="flex justify-center items-center">
                    <div className="mt-4 flex gap-4">
                        {/* İsim Alanı */}
                        <div className="flex flex-col">
                            <label className="font-semibold">İsim</label>
                            <input
                                type="text"
                                maxLength="60"
                                placeholder="İsim"
                                className="p-1 border border-gray-300 rounded-md text-sm w-full"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                            />
                        </div>

                        {/* Fiyat Alanı */}
                        <div className="flex flex-col">
                            <label className="font-semibold">Fiyat</label>
                            <input
                                type="text"
                                placeholder="₺"
                                className="p-1 border border-gray-300 rounded-md text-sm w-full"
                                value={price}
                                onChange={handlePriceChange}
                            />
                        </div>
                    </div>
                </div>

                {/* Scrollable area for count-free count pairs */}
                <div className="flex justify-center items-center">
                    <div className="mt-6 h-32 md:w-1/2 w-full overflow-y-auto border border-gray-200 rounded-md p-2">
                        <ul>
                            {pairs.map((item) => (
                                <li
                                    key={item.id}
                                    className="flex justify-between items-center bg-gray-100 p-2 mb-2 rounded-md"
                                >
                                    <span>
                                        {item.count} adet - {item.free_count} mal fazlası
                                    </span>
                                    <button
                                        className="text-red-500 font-bold"
                                        onClick={() => handleDeletePair(item.id)}
                                    >
                                        ×
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    {/* Alt kısım: 2 küçük input alanı ve ekle butonu */}
                    <div className="mt-4 flex gap-4">
                        <input
                            type="number"
                            max="1000"
                            placeholder="Adet"
                            className="w-full p-1 border border-gray-300 rounded-md text-sm"
                            value={count}
                            onChange={(e) => setCount(e.target.value)}
                        />
                        <input
                            type="number"
                            max="1000"
                            placeholder="Mal Fazlası"
                            className="w-full p-1 border border-gray-300 rounded-md text-sm"
                            value={freeCount}
                            onChange={(e) => setFreeCount(e.target.value)}
                        />
                        <button className="bg-blue-500 text-white px-4 py-1 rounded-md" onClick={handleAddPair}>
                            Ekle
                        </button>
                    </div>
                </div>

                {/* Ekle ve Temizle Butonları */}
                <div className="mt-6 flex justify-center">
                    <button
                        className="bg-blue-500 text-white px-6 py-2 rounded-md text-lg"
                        onClick={handleSaveProduct}
                    >
                        {isLoading ? (
                            <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="white" strokeWidth="4" fill="none" />
                                <path className="opacity-75" fill="white" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z" />
                            </svg>
                        ) : (
                            isEditing ? "Güncelle" : "Ekle"
                        )}

                    </button>
                    <button
                        className="bg-gray-300 text-black px-6 py-2 rounded-md text-lg ml-4"
                        onClick={clearForm}
                    >
                        {isEditing ? "Vazgeç" : "Temizle"}
                    </button>
                </div>
            </div>
        </div>
    </>)
}