global.user = null
global.userData = null

global.authorityLevel = {
    first_manager: 1,
    second_manager: 2,
    employee: 3,
    warehouse: 4
};

global.orderStatus = {
    email_order: 0,
    active_order: 1,
    completed_order: 2,
    deleted_order: 3
};

module.exports = {
    setUser(newUser) {
        global.user = newUser;
    },
    setUserData(newUserData)
    {
        global.userData = newUserData
    },
};