import { useEffect, useState, useRef } from "react";
import React from "react";
import { Bar } from 'react-chartjs-2'
import supabase from "../../Database/DBConfig.js";
import Chart from 'chart.js/auto';

export default function GeneralSummary() {
    const [chart, setChart] = useState(null)
    const [chart2, setChart2] = useState(null)

    useEffect(() => {
        async function getTopSellingMedicines() {

            let query = supabase
                .from('orders')
                .select(`pharmacies!inner(region_id, bricks (name)), order_contents (count, free_count,medicines (id, name))`)
                .eq('company_id', global.userData.company_id)

            if (global.userData.authority_level === global.authorityLevel.second_manager) {
                const regionIds = global.userData.user_region_pairs.map(pair => pair.region_id);
                query = query.in('pharmacies.region_id', regionIds);
            }

            const { data, error } = await query

            if (error) {
                console.log('Error executing query:', error.message);
                return;
            }

            const medicineCount = {};
            const brickCount = {};

            // İlaçları saymak
            data.forEach(order => {
                order.order_contents.forEach(orderContent => {
                    const medicineName = orderContent.medicines.name;
                    medicineCount[medicineName] = (medicineCount[medicineName] || 0) + orderContent.count + orderContent.free_count;

                    const brickName = order.pharmacies.bricks.name
                    brickCount[brickName] = (brickCount[brickName] || 0) + orderContent.count + orderContent.free_count;
                })
            });

            let brickResult = Object.entries(brickCount)
                .map(([name, count]) => ({ name, count }))
                .sort((a, b) => a.count - b.count)

            const brickLabels = brickResult.map(item => item.name)
            const brickCounts = brickResult.map(item => item.count)

            // Bar renkleri hesaplama
            const getColor = (index, total, opacity = 1) => {
                const r = Math.floor((1 - index / total) * 255);
                const g = Math.floor((index / total) * 255);
                return `rgba(${r}, ${g}, 0, ${opacity})`;
            };


            const brickBackgroundColors = brickLabels.map((_, index) => getColor(index, brickLabels.length, 0.4));
            const brickBorderColors = brickLabels.map((_, index) => getColor(index, brickLabels.length, 1));

            // Sıralama ve düzenleme
            let medicineResult = Object.entries(medicineCount)
                .map(([name, count]) => ({ name, count }))
                .sort((a, b) => a.count - b.count)

            medicineResult = medicineResult.slice(medicineResult.length - 10, medicineResult.length);

            const medicineLabels = medicineResult.map(item => item.name);
            const medicineCounts = medicineResult.map(item => item.count);

            const medicineBackgroundColors = medicineCounts.map((_, index) => getColor(index, medicineCounts.length, 0.4));
            const medicineBorderColors = medicineCounts.map((_, index) => getColor(index, medicineCounts.length, 1));

            const medicineChart = {
                data: {
                    labels: medicineLabels,
                    datasets: [{
                        label: 'İlaç Satış Adetleri',
                        data: medicineCounts,
                        backgroundColor: medicineBackgroundColors,
                        borderColor: medicineBorderColors,
                        borderWidth: 1,
                    }],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            // Etiketlerin taşmaması için
                            ticks: {
                                maxRotation: 0, // X eksenindeki yazıların açısını ayarlıyoruz
                                minRotation: 0, // En az açıyı 0 yapıyoruz
                                autoSkip: true, // Yazıların birbirine girmesini engelliyoruz
                            },
                        },
                        y: {
                            beginAtZero: true,
                        },
                    },
                    plugins: {
                        legend: {
                            display: false, // Legend'ı göster
                        },
                    },
                },
            }

            const brickChart = {
                data: {
                    labels: brickLabels,
                    datasets: [{
                        label: 'Brick Satış',
                        data: brickCounts,
                        backgroundColor: brickBackgroundColors,
                        borderColor: brickBorderColors,
                        borderWidth: 1,
                    }],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            // Etiketlerin taşmaması için
                            ticks: {
                                maxRotation: 0, // X eksenindeki yazıların açısını ayarlıyoruz
                                minRotation: 0, // En az açıyı 0 yapıyoruz
                                autoSkip: true, // Yazıların birbirine girmesini engelliyoruz
                            },
                        },
                        y: {
                            beginAtZero: true,
                        },
                    },
                    plugins: {
                        legend: {
                            display: false, // Legend'ı göster
                        },
                    },
                },
            }

            setChart(medicineChart)
            setChart2(brickChart)
        }

        getTopSellingMedicines();
    }, []);

    return (
        <>

            <div className="flex flex-col md:flex-row items-center justify-center gap-20">
                <div className="flex flex-col md:p-6 w-full md:w-1/2 h-96  items-center">
                    <div className="font-semibold whitespace-nowrap">EN ÇOK SATILAN İLAÇLAR</div>
                    {chart && <Bar data={chart.data} options={chart.options} plugins={chart.plugins} />}
                </div>
                <div className="flex flex-col md:p-6 w-full md:w-1/2 h-96 items-center">
                    <div className="font-semibold whitespace-nowrap">EN ÇOK SATIŞ YAPILAN BRİCKLER</div>
                    {chart2 && <Bar data={chart2.data} options={chart2.options} plugins={chart2.plugins} />}
                </div>
            </div >

        </>
    );
}
