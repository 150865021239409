import React, { useEffect, useState } from "react";

export default function UnselectedProductList({ products, onSelected }) {
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
    };

    return (
        <div className="bg-white md:w-1/4 h-72 md:h-96 rounded-lg">
            <div className="p-0.5">
                <div className="relative flex items-center mb-4 m-1">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearch}
                        placeholder="Ürün ara..."
                        className="border border-gray-300 w-full h-8 rounded-md w-full px-1 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 absolute left-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M15.5 10.5a5 5 0 11-10 0 5 5 0 0110 0z" />
                    </svg>
                </div>
                <div className="overflow-auto w-full pr-6 max-h-56 md:max-h-80">
                    <ul className="list-none">
                        {products && products.filter(product => product.name.toLowerCase().includes(searchQuery)).map(product => (
                            <li className="pl-2 mb-2 cursor-pointer" onClick={() => onSelected(product.id)} key={product.id}>
                                <div className={`${product.isSelected ? "bg-red-500" : ""} rounded-md p-2`}>
                                    <div className={`${product.isSelected ? "text-gray-50" : ""}`}>{product.name}</div>
                                    {/* Mal Fazlası Bilgileri için Scrollable Alan */}
                                    <div className={`mt-2 overflow-y-auto max-h-16 border ${product.isSelected ? "border-white" : "border-gray-300"}  rounded-md p-2`}>
                                        {
                                            product.medicine_free_pairs && product.medicine_free_pairs.length > 0 ? (
                                                product.medicine_free_pairs.map((pair) => (
                                                    <div key={pair.id} className={`text-sm ${product.isSelected ? "text-gray-50" : "text-gray-700"}`}>
                                                        {pair.count} adet - {pair.free_count} mal fazlası
                                                    </div>
                                                ))
                                            ) : (
                                                <div className={`text-sm ${product.isSelected ? "text-gray-50" : "text-gray-700"}`}>Mal fazlası yok.</div>
                                            )
                                        }
                                    </div>
                                </div>
                                <hr className="mt-2" />
                            </li>
                        )) || <div className="flex justify-center items-center">
                                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="black" strokeWidth="4" fill="none" />
                                    <path className="opacity-75" fill="black" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z" />
                                </svg>
                            </div>}
                    </ul>
                </div>
            </div>
        </div>
    );
}
