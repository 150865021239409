import Sidebar from "../Components/Sidebar/Sidebar.js"
import UnselectedProductList from "../Components/ProductList/UnselectedProductList.js"
import SelectedProductList from "../Components/ProductList/SelectedProductList.js";
import MailGenerator from "../Utility/MailGenerator.js"
import { getMedicineList } from "../Database/Tables/MedicineTable.js";
import { getPharmacyList } from "../Database/Tables/PharmacyTable.js";
import { getWarehouseList } from "../Database/Tables/Warehouse.js";
import { useState, useEffect } from "react";
import OrderItem from "../Components/Report/OrderItem";

import supabase from '../Database/DBConfig.js'

export default function OrderPage() {
    const [isFetching, setFetching] = useState(false)
    const [isOrdering, setOrdering] = useState(false)
    const [medicines, setMedicines] = useState();
    const [warehouses, setWarehouse] = useState();
    const [pharmacies, setPharmacy] = useState();
    const [warehouseSearchQuery, setWarehouseSearch] = useState("");
    const [pharmacySearchQuery, setPharmacySearch] = useState("");
    const [orderObject, setOrderObject] = useState()
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState("");
    const [isError, setIsError] = useState(false)

    const fetchActiveOrders = async () => {
        let query = supabase
            .from('orders')
            .select('id, status, created_at, contents:order_contents (id, count, free_count, total_price, medicines (id, name)), pharmacies (id, name, bricks (id, name), regions (id, name)), users (uid, email, first_name, last_name), warehouse: warehouse_id (id, company_name)')
            .match({ 'company_id': global.userData.company_id, 'status': global.orderStatus.active_order })
            .order('created_at', { ascending: false });

        if (global.userData.authority_level === global.authorityLevel.employee) {
            query = query.eq('user_id', global.userData.uid);
        }

        let { data: orderData, error: orderError } = await query;

        if (orderError) {
            console.error("Error fetching orders:", orderError);
        } else {
            if (global.userData.authority_level === global.authorityLevel.second_manager) {
                const regionIds = global.userData.user_region_pairs.map(pair => pair.region_id);

                orderData = orderData.filter(order =>
                    regionIds.includes(order.pharmacies.regions.id) ||
                    order.users.uid === global.userData.uid
                );

            }

            setOrders(orderData);
        }
    }

    useEffect(() => {
        if (!pharmacies || !medicines || !warehouses)
            return

        let tempOrderObject = {}

        const selectedPharmacy = pharmacies.find(item => item.isSelected)

        tempOrderObject.selectedPharmacy = selectedPharmacy

        const selectedWarehouse = warehouses.find(item => item.isSelected)

        tempOrderObject.selectedWarehouse = selectedWarehouse

        const selectedMedicines = medicines
            .filter(item => item.isSelected && item.currentQuantity > 0)
            .map(item => ({ ...item }));

        tempOrderObject.selectedMedicines = selectedMedicines

        tempOrderObject.mail = MailGenerator(tempOrderObject)

        tempOrderObject.isReady = tempOrderObject.selectedPharmacy != null && tempOrderObject.selectedWarehouse != null && tempOrderObject.selectedMedicines.length > 0
        setOrderObject(tempOrderObject)

    }, [pharmacies, warehouses, medicines])


    useEffect(() => {
        const fetchData = async () => {
            setFetching(true)
            const { data: medicineList } = await getMedicineList(global.userData.company_id)

            const formattedMedicineList = medicineList.map(medicine => ({
                ...medicine,
                currentQuantity: medicine.medicine_free_pairs.length > 0 ? medicine.medicine_free_pairs[0].count : 10, // currentQuantity ekleniyor
                currentFreeCount: medicine.medicine_free_pairs.length > 0 ? medicine.medicine_free_pairs[0].free_count : 0, // currentFreeCount ekleniyor
                isSelected: false
            }));

            setMedicines(formattedMedicineList)

            const { data: warehouseList } = await getWarehouseList()

            const formattedWarehouseList = warehouseList.map(warehouse => ({
                id: warehouse.id,
                name: warehouse.company_name,
                email: warehouse.email,
                isRegistered: warehouse.is_registered,
                isSelected: false
            }))

            setWarehouse(formattedWarehouseList)

            const { data: pharmacyList } = await getPharmacyList()

            setPharmacy(pharmacyList)

            setFetching(false)
        }

        fetchData()
        fetchActiveOrders()
    }, [])

    const handleOrder = async () => {
        if (!orderObject || !orderObject.isReady)
            return

        setError("")
        setOrdering(true)

        const { data: orderData, error: orderError } = await supabase
            .from('orders')
            .insert({
                user_id: global.userData.uid,
                pharmacy_id: orderObject.selectedPharmacy.id,
                warehouse_id: orderObject.selectedWarehouse.id,
                status: orderObject.selectedWarehouse.isRegistered ? 1 : 0,
                company_id: global.userData.company_id
            })
            .select();

        if (orderError) {
            setOrdering(false)
            setIsError(true)
            setError("Bir hata meydana geldi!")
            return
        }

        const orderContents = orderObject.selectedMedicines.map(medicine => ({
            order_id: orderData[0].id,
            medicine_id: medicine.id,
            count: medicine.currentQuantity,
            free_count: medicine.currentFreeCount,
            total_price: medicine.currentQuantity * medicine.price
        }));

        const { error: contentError } = await supabase
            .from('order_contents')
            .insert(orderContents);

        if (contentError) {
            setOrdering(false)
            setIsError(true)
            setError("Bir hata meydana geldi!")
            return
        }

        setIsError(false)
        setError("Sipariş gönderildi")
        setOrdering(false)
        fetchActiveOrders()
    }

    function onProductSelected(id) {
        setMedicines(medicines.map(medicine =>
            medicine.id === id
                ? { ...medicine, isSelected: !medicine.isSelected }
                : medicine
        ));
    }

    function onQuantityChanged(id, quantity, freeCount) {
        setMedicines(medicines.map(medicine =>
            medicine.id === id
                ? { ...medicine, currentQuantity: quantity, currentFreeCount: freeCount }
                : medicine
        ));
    }

    function onWarehouseSelected(id) {
        setWarehouse(warehouses.map(warehouse =>
            warehouse.id === id
                ? { ...warehouse, isSelected: true }
                : { ...warehouse, isSelected: false }
        ));
    }

    function onPharmacySelected(id) {
        setPharmacy(pharmacies.map(pharmacy =>
            pharmacy.id === id
                ? { ...pharmacy, isSelected: true }
                : { ...pharmacy, isSelected: false }
        ));
    }

    function handleWarehouseSearchQuery(e) {
        const query = e.target.value.toLowerCase()
        setWarehouseSearch(query)
    }

    function handlePharmacySearchQuery(e) {
        const query = e.target.value.toLowerCase()
        setPharmacySearch(query)
    }

    return (
        <>
            <Sidebar />
            <div className="ml-12 md:ml-40 p-2">
                <div className="flex flex-col gap-4 md:flex-row mr-1">
                    <UnselectedProductList products={medicines} onSelected={onProductSelected} />
                    <SelectedProductList products={medicines} onQuantityChanged={onQuantityChanged} onSelected={onProductSelected} />
                </div>
                <div className="flex flex-row gap-2 md:flex-row mr-1 mt-5">
                    <div className="bg-white w-1/2 md:w-1/4 h-56 md:h-80 rounded-lg pt-0.5">
                        <div className="relative flex items-center mb-4">
                            <input
                                type="text"
                                value={warehouseSearchQuery}
                                onChange={handleWarehouseSearchQuery}
                                placeholder="Depo ara..."
                                className="border border-gray-300 h-8 m-1 rounded-md w-full px-1 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 absolute left-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M15.5 10.5a5 5 0 11-10 0 5 5 0 0110 0z" />
                            </svg>
                        </div>
                        <div className="overflow-auto max-h-40 md:max-h-64">
                            <ul className="list-none">
                                {warehouses && warehouses.filter(warehouse => warehouse.name.toLowerCase().includes(warehouseSearchQuery)).map(warehouse => (
                                    <>
                                        <li className={`pl-2 pt-1 pb-1 cursor-pointer ${warehouse.isSelected ? 'bg-red-500 text-white' : ''}`} onClick={() => onWarehouseSelected(warehouse.id)} key={warehouse.id}>
                                            {warehouse.name} {warehouse.isRegistered ? "(ASİSTAN)" : "(EMAİL)"}
                                        </li>
                                        <hr />
                                    </>
                                )) || <svg className="animate-spin mx-auto h-5 w-5" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="black" strokeWidth="4" fill="none" />
                                        <path className="opacity-75" fill="black" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z" />
                                    </svg>}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white w-1/2 md:w-1/4 h-56 md:h-80 rounded-lg pt-0.5">
                        <div className="relative flex items-center mb-4">
                            <input
                                type="text"
                                value={pharmacySearchQuery}
                                onChange={handlePharmacySearchQuery}
                                placeholder="Eczane ara..."
                                className="border border-gray-300 h-8  m-1 rounded-md w-full px-1 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 absolute left-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M15.5 10.5a5 5 0 11-10 0 5 5 0 0110 0z" />
                            </svg>
                        </div>
                        <div className="overflow-auto max-h-40 md:max-h-64">
                            <ul className="list-none">
                                {pharmacies && pharmacies.filter(pharmacy => pharmacy.name.toLowerCase().includes(pharmacySearchQuery)).map(pharmacy => (
                                    <>
                                        <li className={`pl-2 pt-1 pb-1 cursor-pointer ${pharmacy.isSelected ? 'bg-red-500 text-white' : ''}`} onClick={() => onPharmacySelected(pharmacy.id)} key={pharmacy.id}>
                                            {pharmacy.name}
                                        </li>
                                        <hr />
                                    </>
                                )) || <svg className="animate-spin mx-auto h-5 w-5" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="black" strokeWidth="4" fill="none" />
                                        <path className="opacity-75" fill="black" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z" />
                                    </svg>}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white md:w-2/4 p-4 h-40 md:h-80 rounded-lg hidden md:block overflow-auto">
                        {orderObject && <div dangerouslySetInnerHTML={{ __html: orderObject.mail }} />}
                    </div>
                </div>
                <div className="bg-white mt-5 md:w-2/4 p-4 md:h-80 h-80 rounded-lg block md:hidden overflow-auto">
                    {orderObject && <div dangerouslySetInnerHTML={{ __html: orderObject.mail }} />}
                </div>
                <div className="flex flex-col items-center gap-2 my-4">
                    <div className={`${isError ? "text-red-600" : "text-green-600"} h-5 font-bold`}>{error}</div>
                    {orderObject?.isReady ? (
                        <div className="flex justify-center">
                            <button onClick={handleOrder}
                                className="bg-blue-500 text-white w-60 h-12 text-xl py-2 px-4 rounded-lg hover:bg-blue-600 transition ease-in-out duration-300"
                            >
                                {isOrdering ? (
                                    <svg className="animate-spin mx-auto h-5 w-5" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="white" strokeWidth="4" fill="none" />
                                        <path className="opacity-75" fill="white" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z" />
                                    </svg>
                                ) : ("Sipariş Ver")}
                            </button>
                        </div>
                    ) : (<div className="h-12"></div>)}
                </div>
                <div className="bg-white h-auto p-4 rounded-lg">
                    {orders && orders.length > 0 ? (
                        <>
                            <div className="text-center font-bold mb-2">Aktif Siparişler</div>
                            <ul>
                                {orders.map(order => (
                                    <li key={order.id}>
                                        <OrderItem order={order} /> {/* OrderItem bileşenini kullan */}
                                    </li>
                                ))}
                            </ul>
                        </>
                    ) : (
                        <div className="text-center font-bold">Aktif Sipariş Yok</div>
                    )}
                </div>

            </div>
        </>
    )
}