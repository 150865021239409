import SelectedProduct from "./SelectedProduct";

export default function SelectedProductList({ products, onSelected, onQuantityChanged }) {
    return (
        <div className="bg-white md:w-3/4 h-72 md:h-96 rounded-lg">
            <div className="bg-white h-72 md:h-96 overflow-auto p-4 rounded-lg">
                <table className="w-full border-collapse">
                    <tr>
                        <th>Ürün Adı</th>
                        <th>Adet</th>
                        <th>Mal Fazlası</th>
                        <th>Toplam Adet</th>
                        <th>Birim Fiyat</th>
                        <th>Toplam Fiyat</th>
                    </tr>
                    {products && products.filter(product => product.isSelected).map(product => {
                        return (<SelectedProduct product={product} onQuantityChanged={onQuantityChanged} onSelected={onSelected} />)
                    }
                    )}
                </table>
            </div>
        </div>
    );
}