import supabase from "../DBConfig";

const CACHE_DURATION = 0;

const TABLE_NAME = "medicines"
const TIME_STORAGE_KEY = "lastFetchTimeKey" + TABLE_NAME
const CACHE_STORAGE_KEY = "cachedKey" + TABLE_NAME

export const getMedicineList = async (company_id) => {

    const storedFetchTime = sessionStorage.getItem(TIME_STORAGE_KEY);
    const lastFetchTime = storedFetchTime ? parseInt(storedFetchTime, 10) : null;

    if (false && lastFetchTime && (Date.now() - lastFetchTime < CACHE_DURATION)) {

        const cachedData = sessionStorage.getItem(CACHE_STORAGE_KEY)

        if (cachedData) {
            const data = JSON.parse(cachedData)
            return { data };
        }
    }

    let query = supabase
        .from(TABLE_NAME)
        .select('*, medicine_free_pairs (id, count, free_count)')
        .match({ 'company_id': company_id, 'is_deleted': false })
        .order('name');
        
    if (global.userData.authority_level === global.authorityLevel.employee) {
        const medicineIds = global.userData.user_medicine_pairs.map(pair => pair.medicine_id);
        query = query.in('id', medicineIds);
    }

    const { data, error } = await query;

    if (error) throw new Error(error.message);

    sessionStorage.setItem(CACHE_STORAGE_KEY, JSON.stringify(data))
    sessionStorage.setItem(TIME_STORAGE_KEY, Date.now());

    return { data, error };
};