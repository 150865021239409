import SidebarItem from "./SidebarItem.js";
import { useNavigate } from "react-router-dom";
import supabase from '../../Database/DBConfig.js'

export default function Sidebar() {
    const navigate = useNavigate()

    async function signOut() {
        const { error } = await supabase.auth.signOut()

        navigate("/login")
    }

    function onSidebarClicked(path) {
        if (path === "signout") {
            signOut()
            return
        }

        navigate(path)
    }

    function renderSidebar() {
        switch (global.userData.authority_level) {
            case global.authorityLevel.first_manager:
                return [
                    <SidebarItem key="order" path="/" iconName="medicine" text={"Sipariş"} onClick={onSidebarClicked} />,
                    <SidebarItem key="summary" path="/summary" iconName="medicine" text={"Özet"} onClick={onSidebarClicked} />,
                    <SidebarItem key="report" path="/report" iconName="medicine" text={"Rapor"} onClick={onSidebarClicked} />,
                    <SidebarItem key="product" path="/product" iconName="medicine" text={"Ürünler"} onClick={onSidebarClicked} />,
                    <SidebarItem key="user" path="/users" iconName="medicine" text={"Kullanıcılar"} onClick={onSidebarClicked} />
                ]
                break
            case global.authorityLevel.second_manager:
                return [
                    <SidebarItem key="order" path="/" iconName="medicine" text={"Sipariş"} onClick={onSidebarClicked} />,
                    <SidebarItem key="summary" path="/summary" iconName="medicine" text={"Özet"} onClick={onSidebarClicked} />,
                    <SidebarItem key="report" path="/report" iconName="medicine" text={"Rapor"} onClick={onSidebarClicked} />,
                    <SidebarItem key="product" path="/product" iconName="medicine" text={"Ürünler"} onClick={onSidebarClicked} />,
                    <SidebarItem key="user" path="/users" iconName="medicine" text={"Kullanıcılar"} onClick={onSidebarClicked} />
                ];
                break
            case global.authorityLevel.employee:
                return [
                    <SidebarItem key="order" path="/" iconName="medicine" text={"Sipariş"} onClick={onSidebarClicked} />,
                    <SidebarItem key="report" path="/report" iconName="medicine" text={"Rapor"} onClick={onSidebarClicked} />,
                    <SidebarItem key="product" path="/product" iconName="medicine" text={"Ürünler"} onClick={onSidebarClicked} />,
                ];
                break
        }
    }

    const authorityLevelFilter = [{ text: "Satış Müdürü", level: global.authorityLevel.first_manager }, { text: "Bölge Müdürü", level: global.authorityLevel.second_manager }, { text: "Mümessil", level: global.authorityLevel.employee }]


    renderSidebar()
    return (
        <div className="bg-white fixed left-0 top-0 h-full w-12 flex flex-col md:w-40">
            <ul className="list-none mt-3 flex-grow">
                {renderSidebar().map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
            <div className="mb-3">
                <div className="hidden md:block ml-4 mb-4">
                    <div className="font-semibold">{global.userData.first_name} {global.userData.last_name}</div>
                    <div>{authorityLevelFilter.find(authority => authority.level === global.userData.authority_level).text}</div>
                </div>
                <SidebarItem path="signout" iconName="log-out" text={"ÇIKIŞ"} onClick={onSidebarClicked} />
            </div>
        </div>
    );
}
