import Sidebar from "../Components/Sidebar/Sidebar.js";
import { useEffect, useState } from "react";
import supabase from "../Database/DBConfig.js";
import OrderItem from "../Components/Report/OrderItem.js";
import { getPharmacyList } from "../Database/Tables/PharmacyTable.js"
import { getRegionList } from "../Database/Tables/RegionsTable.js";
import { getBrickList } from "../Database/Tables/BricksTable.js";
import { getWarehouseList } from "../Database/Tables/Warehouse.js";
import Select, { StylesConfig } from 'react-select';
import { getMedicineList } from "../Database/Tables/MedicineTable.js";
import chroma from 'chroma-js';
import React from 'react';

const orderStatusColorStyle: StylesConfig<orderStatusTemplate, true> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            fontWeight: "500",
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : color.alpha(0.3).css()
                    : undefined,
            },
        };
    },
    multiValue: (styles, { data }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            fontWeight: "500",
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        fontWeight: "500",
        color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        fontWeight: "500",
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
        },
    }),
};

const orderStateOptions =
    [{ id: global.orderStatus.email_order, name: "Mail", color: "gray" },
    { id: global.orderStatus.active_order, name: "Aktif", color: "orange" },
    { id: global.orderStatus.completed_order, name: "Tamamlandı", color: "green" },
    { id: global.orderStatus.deleted_order, name: "İptal Edildi", color: "red" }]

export default function ReportPage() {
    const [orders, setOrders] = useState([]);
    const [userOptions, setUserOptions] = useState();
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [pharmacyOptions, setPharmacyOptions] = useState();
    const [filteredPharmacyOptions, setFilteredPharmacyOptions] = useState([]);
    const [selectedPharmacies, setSelectedPharmacies] = useState([]);

    const [regionOptions, setRegionOptions] = useState();
    const [selectedRegions, setSelectedRegions] = useState([]);

    const [brickOptions, setBrickOptions] = useState();
    const [filteredBrickOptions, setFilteredBrickOptions] = useState([]);
    const [selectedBricks, setSelectedBricks] = useState([]);

    const [warehouseOptions, setWarehouseOptions] = useState();
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);

    const [medicineOptions, setMedicineOptions] = useState();
    const [selectedMedicines, setSelectedMedicines] = useState([]);

    const [selectedStatuses, setSelectedStatuses] = useState([]);

    useEffect(() => {
        if (selectedRegions.length === 0) {
            // Eğer hiç region seçilmezse, tüm brick'leri göster
            setFilteredBrickOptions(brickOptions);
        } else {
            // Seçilen region'ların id'lerine göre brick'leri filtrele
            const selectedRegionIds = selectedRegions.map(region => region.id);
            const filtered = brickOptions.filter(brick => selectedRegionIds.includes(brick.region_id));

            const updatedSelectedBricks = selectedBricks.filter(brick => selectedRegionIds.includes(brick.region_id));

            setSelectedBricks(updatedSelectedBricks);
            setFilteredBrickOptions(filtered);
        }
    }, [selectedRegions]);

    useEffect(() => {
        if (selectedRegions.length === 0 && selectedBricks.length === 0) {
            // Eğer region ve brick seçilmezse, tüm pharmacy'leri göster
            setFilteredPharmacyOptions(pharmacyOptions);
        } else {
            // Seçilen region'ların ve brick'lerin id'lerine göre pharmacy'leri filtrele
            const selectedRegionIds = selectedRegions.map(region => region.id);
            const selectedBrickIds = selectedBricks.map(brick => brick.id);

            const filtered = pharmacyOptions.filter(pharmacy => {
                const isRegionMatch = selectedRegionIds.length === 0 || selectedRegionIds.includes(pharmacy.region_id);
                const isBrickMatch = selectedBrickIds.length === 0 || selectedBrickIds.includes(pharmacy.brick_id);
                return isRegionMatch && isBrickMatch;
            });

            // Seçili pharmacy'lerin, yeni filtreye uyanlarını koru
            const updatedSelectedPharmacies = selectedPharmacies.filter(pharmacy => {
                const isRegionMatch = selectedRegionIds.includes(pharmacy.region_id) || selectedRegionIds.length == 0;
                const isBrickMatch = selectedBrickIds.includes(pharmacy.brick_id) || selectedBrickIds.length == 0;
                return isRegionMatch && isBrickMatch;
            });

            setSelectedPharmacies(updatedSelectedPharmacies); // Seçili pharmacy'leri güncelle
            setFilteredPharmacyOptions(filtered); // Filtrelenmiş pharmacy'leri güncelle
        }
    }, [selectedRegions, selectedBricks]);



    useEffect(() => {
        const fetchReport = async () => {
            let query = supabase
                .from('orders')
                .select('id, status, created_at, contents:order_contents (id, count, free_count, total_price, medicines (id, name)), pharmacies (id, name, bricks (id, name), regions (id, name)), users (uid, email, first_name, last_name), warehouse: warehouse_id (id, company_name)')
                .order('created_at', { ascending: false })
                .eq('company_id', global.userData.company_id);

            if (global.userData.authority_level === global.authorityLevel.employee) {
                query = query.eq('user_id', global.userData.uid);
            }

            let { data: orderData, error: orderError } = await query

            if (orderError) {
                console.error("Error fetching orders:", orderError);
            } else {
                if (global.userData.authority_level === global.authorityLevel.second_manager) {
                    const regionIds = global.userData.user_region_pairs.map(pair => pair.region_id);
                    orderData = orderData.filter(order =>
                        regionIds.includes(order.pharmacies.regions.id) ||
                        order.users.uid === global.userData.uid
                    );
                }

                setOrders(orderData);
            }
        };

        const fetchFilters = async () => {

            let query = supabase
                .from('users')
                .select('uid, first_name, last_name, email, user_brick_pairs (id, brick_id, bricks (region_id))')
                .match({ 'company_id': global.userData.company_id, 'is_registered': true, 'is_deleted': false })

            if (global.userData.authority_level === global.authorityLevel.employee) {
                query = query.eq('uid', global.userData.uid);
            }

            let { data: userData, error: userError } = await query

            if (userError) {
                console.error("Error fetching users:", userError);
            } else {
                if (global.userData.authority_level === global.authorityLevel.second_manager) {
                    const regionIds = global.userData.user_region_pairs.map(pair => pair.region_id);

                    userData = userData.filter(user =>
                        user.user_brick_pairs.some(pair => regionIds.includes(pair.bricks?.region_id)) ||
                        user.user_brick_pairs.length === 0 ||
                        user.uid === global.userData.uid
                    );
                }

                setUserOptions(userData);
            }

            const { data: pharmacyData, error: pharmacyError } = await getPharmacyList()

            if (pharmacyError) {
                console.error("Error fetching pharmacies:", pharmacyError);
            } else {
                setPharmacyOptions(pharmacyData)
                setFilteredPharmacyOptions(pharmacyData)
            }

            const { data: regionData, error: regionError } = await getRegionList()

            if (regionError) {
                console.error("Error fetching regions:", regionError);
            } else {
                setRegionOptions(regionData)
            }

            const { data: brickData, error: brickError } = await getBrickList()

            if (brickError) {
                console.error("Error fetching bricks:", brickError);
            } else {
                setBrickOptions(brickData)
                setFilteredBrickOptions(brickData)
            }

            const { data: warehouseData, error: warehouseError } = await getWarehouseList()

            if (warehouseError) {
                console.error("Error fetching warehouses:", warehouseError);
            } else {
                setWarehouseOptions(warehouseData)
            }

            const { data: medicineData, error: medicineError } = await getMedicineList(global.userData.company_id)

            if (medicineError) {
                console.error("Error fetching medicines:", medicineError);
            } else {
                setMedicineOptions(medicineData)
            }
        }

        fetchReport();
        fetchFilters();
    }, []);

    const filterOrders = () => {
        return orders.filter(order => {
            // Check status filter
            const statusMatch =
                selectedStatuses.length === 0 || selectedStatuses.some(status => status.id === order.status);

            // Check medicine filter
            const medicineMatch =
                selectedMedicines.length === 0 ||
                selectedMedicines.some(medicine => order.contents.some(content => medicine.id === content.medicines.id)
                );

            // Check warehouse filter
            const warehouseMatch =
                selectedWarehouses.length === 0 || selectedWarehouses.some(warehouse => warehouse.id === order.warehouse.id);

            // Check brick filter
            const brickMatch =
                selectedBricks.length === 0 || selectedBricks.some(brick => brick.id === order.pharmacies.bricks.id);

            // Check region filter
            const regionMatch =
                selectedRegions.length === 0 || selectedRegions.some(region => region.id === order.pharmacies.regions.id);

            // Check user filter
            const userMatch =
                selectedUsers.length === 0 || selectedUsers.some(user => user.uid === order.users.uid);

            const pharmacyMatch = selectedPharmacies.length === 0 || selectedPharmacies.some(pharmacy => pharmacy.id === order.pharmacies.id);
            // Combine all conditions
            return statusMatch && medicineMatch && warehouseMatch && brickMatch && regionMatch && userMatch && pharmacyMatch;
        });
    };

    return (
        <>
            <Sidebar />
            <div className="ml-12 md:ml-40 p-2">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col bg-white rounded-lg h-full">
                        <div className="flex flex-col sm:flex-row justify-center">
                            <Select
                                onChange={setSelectedUsers}
                                isLoading={!userOptions}
                                isSearchable={true}
                                isMulti
                                className="m-2 sm:w-1/2"
                                options={userOptions}
                                placeholder="Kullanıcı filtrele"
                                getOptionValue={option => `${option['uid']}`}
                                getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                                noOptionsMessage={() => ("Seçenek Yok")}
                            />
                            <Select
                                onChange={setSelectedMedicines}
                                isLoading={!medicineOptions}
                                isSearchable={true}
                                isMulti
                                className="m-2 sm:w-1/2"
                                options={medicineOptions}
                                placeholder="İlaç filtrele"
                                getOptionValue={option => `${option['id']}`}
                                getOptionLabel={option => `${option.name}`}
                                noOptionsMessage={() => ("Seçenek Yok")}
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row justify-center">
                            <Select
                                onChange={setSelectedRegions}
                                isLoading={!regionOptions}
                                isSearchable={true}
                                isMulti
                                className="m-2 sm:w-1/2"
                                options={regionOptions}
                                placeholder="Bölge filtrele"
                                getOptionValue={option => `${option['id']}`}
                                getOptionLabel={option => `${option.name}`}
                                noOptionsMessage={() => ("Seçenek Yok")}
                            />
                            <Select
                                onChange={setSelectedBricks}
                                isLoading={!brickOptions}
                                isSearchable={true}
                                isMulti
                                className="m-2 sm:w-1/2"
                                value={selectedBricks}
                                options={filteredBrickOptions}
                                placeholder="Brick filtrele"
                                getOptionValue={option => `${option['id']}`}
                                getOptionLabel={option => `${option.name}`}
                                noOptionsMessage={() => ("Seçenek Yok")}
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row justify-center">
                            <Select
                                onChange={setSelectedPharmacies}
                                isLoading={!pharmacyOptions}
                                isSearchable={true}
                                isMulti
                                className="m-2 sm:w-1/2"
                                options={filteredPharmacyOptions}
                                value={selectedPharmacies}
                                placeholder="Eczane filtrele"
                                getOptionValue={option => `${option['id']}`}
                                getOptionLabel={option => `${option.name}`}
                                noOptionsMessage={() => ("Seçenek Yok")}
                            />
                            <Select
                                onChange={setSelectedWarehouses}
                                isLoading={!warehouseOptions}
                                isSearchable={true}
                                isMulti
                                className="m-2 sm:w-1/2"
                                options={warehouseOptions}
                                placeholder="Depo filtrele"
                                getOptionValue={option => `${option['id']}`}
                                getOptionLabel={option => `${option.company_name}`}
                                noOptionsMessage={() => ("Seçenek Yok")}
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row justify-center">
                            <Select
                                onChange={setSelectedStatuses}
                                isSearchable={false}
                                isMulti
                                className="m-2 sm:w-1/2"
                                options={orderStateOptions}
                                placeholder="Durum filtrele"
                                getOptionValue={option => `${option['id']}`}
                                getOptionLabel={option => `${option.name}`}
                                styles={orderStatusColorStyle}
                                noOptionsMessage={() => ("Seçenek Yok")}
                            />
                        </div>
                    </div>
                    <div className="bg-white rounded-lg h-auto p-4">
                        <ul>
                            {filterOrders().map(order => (
                                <li key={order.id}>
                                    <OrderItem order={order} /> {/* OrderItem bileşenini kullan */}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
