import Sidebar from "../Components/Sidebar/Sidebar.js";
import { useState } from "react";
import React from "react";
import GeneralSummary from "../Components/Summaries/GeneralSummary.js"

function MedicineDetails() {
    return <div className="p-4">İlaç detayları buraya gelir.</div>;
}

function PersonDetails() {
    return <div className="p-4">Kişi detayları buraya gelir.</div>;
}

export default function SummaryPage() {
    // Dinamik tab listesi
    const tabs = [
        { id: "general", label: "Genel", component: <GeneralSummary /> },
        { id: "medicine-detail", label: "İlaç Detay", component: <MedicineDetails /> },
        { id: "salesman-detail", label: "Mümessil Detay", component: <PersonDetails /> },
    ];

    // Seçili tab'ı kontrol eden state
    const [selectedTab, setSelectedTab] = useState(tabs[0].id); // Varsayılan olarak ilk tab seçili

    // Seçili tab'ın içeriğini döndürme
    const renderContent = () => {
        const activeTab = tabs.find((tab) => tab.id === selectedTab);
        return activeTab ? activeTab.component : null;
    };

    return (
        <>
            <Sidebar />
            <div className="ml-12 md:ml-40 flex flex-col items-center justify-center p-4">
                {/* Tablar */}
                <div className="bg-white shadow rounded-lg w-full">
                    <div className="flex justify-around border-b border-gray-300">
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                onClick={() => setSelectedTab(tab.id)}
                                className={`flex-1 py-3 text-center text-sm font-medium transition-all ${
                                    selectedTab === tab.id
                                        ? "border-b-2 border-blue-500 text-blue-500"
                                        : "text-gray-500 hover:text-blue-500"
                                }`}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>

                    {/* Tab içeriği */}
                    <div className="p-6">{renderContent()}</div>
                </div>
            </div>
        </>
    );
}
