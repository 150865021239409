import { useLocation } from "react-router-dom";


export default function SidebarItem({ path, iconName, text, onClick }) {
    const location = useLocation();

    const isActive = location.pathname === path;
    
    const color = isActive ? 'bg-[#3B6083]' : 'bg-[#D9D9D9]';

    return (
        <button onClick={() => onClick(path)} className="w-full h-12 flex justify-center items-center md:pl-2 md:justify-start">
            <div className={`w-8 h-8 md:w-10 md:h-10 ${color} rounded-full overflow-hidden flex justify-center items-center`}>
                <img src={`/Icons/Sidebar/${iconName}.png`} alt="Icon" className="w-6 h-6 md:w-7 md:h-7" />
            </div>
            <div className="hidden md:ml-2 text-sm md:text-black md:font-bold md:block">{text}</div>
        </button>
    );
}