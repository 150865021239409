import Sidebar from "../Components/Sidebar/Sidebar.js";
import React, { useEffect, useState } from "react";
import supabase from "../Database/DBConfig.js";
import AddProduct from "../Components/AddProduct.js";
import { getMedicineList } from "../Database/Tables/MedicineTable.js";

export default function ProductPage() {
    // State to store product informationü
    const [medicineList, setMedicineList] = useState([]); // Liste için yeni state
    const [editingProduct, setEditingProduct] = useState({})
    const [isEditing, setIsEditing] = useState(false); // Düzenleme durumu için
    const [isFetching, setIsFetching] = useState(false)
    const [searchQuery, setSearchQuery] = useState("");

    const fetchData = async () => {
        setIsFetching(true)
        const { data: medicines, error: medicineError } = await getMedicineList(global.userData.company_id)

        if (medicineError) {
            console.error("Error fetching medicines:", medicineError);
            return;
        }
        setMedicineList(medicines);
        setIsFetching(false)
    };

    useEffect(() => {
        fetchData(); // Fetch data on component mount
    }, []);

    // Handle editing a product
    const handleEditProduct = (product) => {
        setIsEditing(true); // Set editing state
        setEditingProduct(product); // Set the product ID being edited
    };

    // Handle delete confirmation
    const confirmDelete = async (id) => {
        if (window.confirm("Emin misiniz? Bu işlem geri alınamaz.")) {
            // Soft delete the product
            const { error } = await supabase
                .from('medicines')
                .update({ is_deleted: true })
                .eq('id', id);

            if (error) {
                console.error("Error deleting product:", error);
                return;
            }

            // Hard delete pairs associated with this product
            await supabase
                .from('medicine_free_pairs')
                .delete()
                .eq('medicine_id', id);

            // Refresh the product list
            //clearForm()
            fetchData();

            if (id === editingProduct.id) {
                setEditingProduct({})
                setIsEditing(false)
            }
        }
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
    };

    const isAddingActive = global.userData.authority_level === global.authorityLevel.first_manager

    return (
        <>
            <Sidebar />
            <div className="ml-12 md:ml-40 p-2">
                {isAddingActive && <AddProduct isEditing={isEditing} editingProduct={editingProduct} onProductAdded={() => { fetchData() }} onProductUpdated={() => { fetchData() }} onCanceled={() => { setIsEditing(false); setEditingProduct({}) }} />}

                {/* İlaç ve Mal Fazlası Listesi */}
                <div className={`${isAddingActive ? "mt-8" : ""} w-full h-auto bg-white p-4 rounded-lg shadow-md`}>
                    <div className="m-2 flex flex-col justify-center items-center">
                        <label className="font-bold text-lg">İlaç Listesi</label>
                        {/* Arama Kutusu */}
                        <div className="relative flex items-center">
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={handleSearch}
                                placeholder="Ürün ara..."
                                className="border border-gray-300 rounded-md px-3 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 absolute left-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M15.5 10.5a5 5 0 11-10 0 5 5 0 0110 0z" />
                            </svg>
                        </div>
                    </div>

                    <div className="overflow-y-auto max-h-96 border border-gray-200 rounded-md p-2">
                        {isFetching ? (
                            <div className="flex justify-center">
                                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="black" strokeWidth="4" fill="none" />
                                    <path className="opacity-75" fill="black" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z" />
                                </svg>
                            </div>
                        ) : (medicineList.length === 0 ? (
                            <div className="text-center text-gray-500">Ürün bulunamadı</div> // 'b' senaryosu
                        ) : (
                            <ul>
                                {medicineList.filter(medicine =>
                                    medicine.name.toLowerCase().includes(searchQuery)).map((medicine) => (
                                        <li key={medicine.id} className={` ${medicine.id === editingProduct.id ? 'bg-gray-200' : 'bg-gray-50'} p-4 mb-2 rounded-md shadow-sm flex flex-col`}>
                                            <div className="flex justify-between items-center mb-2">
                                                <div className="flex-1 overflow-hidden">
                                                    <span className="font-semibold text-lg truncate">{medicine.name}</span>
                                                    <span className="text-gray-600 block">{medicine.price}₺</span>
                                                </div>
                                                {global.userData.authority_level === global.authorityLevel.first_manager &&
                                                    <div className="flex space-x-2">
                                                        <button
                                                            className="text-blue-500 font-bold hover:underline"
                                                            onClick={() => handleEditProduct(medicine)}
                                                        >
                                                            Düzenle
                                                        </button>
                                                        <button
                                                            className="text-red-500 font-bold hover:underline"
                                                            onClick={() => confirmDelete(medicine.id)}
                                                        >
                                                            Sil
                                                        </button>
                                                    </div>}
                                            </div>
                                            {/* Mal Fazlası Bilgileri için Scrollable Alan */}
                                            < div className="overflow-y-auto max-h-16 border border-gray-300 rounded-md p-2" >
                                                {
                                                    medicine.medicine_free_pairs.length > 0 ? (
                                                        medicine.medicine_free_pairs.map((pair) => (
                                                            <div key={pair.id} className="text-sm text-gray-700">
                                                                {pair.count} adet - {pair.free_count} mal fazlası
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="text-sm text-gray-500">Mal fazlası yok.</div>
                                                    )
                                                }
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        ))}
                    </div>
                </div >
            </div >
        </>
    );
}
